<template>
  <div
    role="img"
    class="a-avatar"
  >
    <img
      v-if="src"
      :src="src"
      class="img-avatar"
      :class="size"
      alt=""
    />
    <div
      v-else
      class="text-avatar"
      :class="size"
    >
      {{ initials }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'a-avatar',
  props: {
    /**
     * Defines the src property of the image to be rendered.
     */
    src: String,
    /**
     * Defines the name value that will be displayed as initials alongside the avatar.
     */
    name: {
      type: String,
      default: '',
    },
    /**
     * Defines the size of the image to be rendered.
     * @values small, medium, medium-plus, large
     */
    size: {
      default: 'small',
      type: String,
    },
  },
  computed: {
    initials() {
      let initials = this.name.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      return initials;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-primary-purple: #662e80;
$color-primary-white: #ffffff;

.a-avatar {
  .text-avatar {
    border-radius: 50%;
    background-color: var(--dscl-avatar-bg, var(--dscl-primary, #{$color-primary-purple}));
    text-align: center;
    color: var(--dscl-light-font, #{$color-primary-white});
    display: inline-block;
    box-sizing: border-box;
  }

  .small {
    padding-top: 6px;
    font-size: 12px;
    height: 32px;
    width: 32px;
  }

  .medium {
    padding-top: 14px;
    font-size: 18px;
    height: 48px;
    width: 48px;
  }

  .medium-plus {
    padding-top: 26px;
    font-size: 24px;
    height: 72px;
    width: 72px;
  }

  .large {
    padding-top: 36px;
    font-size: 36px;
    height: 96px;
    width: 96px;
  }

  .img-avatar {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 0;
    object-fit: cover;
  }
}
</style>
